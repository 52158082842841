import httpClient from './httpClient';

const prefix = '/customers';

export default {
  async get(page, perpage) {
    const res = await httpClient({ requiresAuth: true }).get(prefix, { params: { page, perpage } });
    return res.data;
  },
  async show(id) {
    const res = await httpClient({ requiresAuth: true }).get(`${prefix}/${id}`);
    
    return res.data;
  },
  async post(data) {
    const res = await httpClient({ requiresAuth: true }).post(prefix, data);
    return res.data;
  },
  async vehicle(customer_id, body) {
    const res = await httpClient({ requiresAuth: true }).post(`/vehicle/${customer_id}`, body);
    return res.data;
  },
  async loan(customer_id, body) {
    const res = await httpClient({ requiresAuth: true }).post(`/loan/${customer_id}`, body);
    return res.data;
  },
  async requireInstall(customer_id, body) {
    const res = await httpClient({ requiresAuth: true }).post(`/require-install/${customer_id}`, body);
    
    return res.data;
  },
  async inspection(customer_id, body) {
    const res = await httpClient({ requiresAuth: true }).post(`/inspection/${customer_id}`, body);
    
    return res.data;
  }
};
